<template>
    <div class="story">
        <div class="cntStory" :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'margin-top: 70px' : ''">
            <template v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
                <div v-for="(storia, index) in story" :key="index" class="cntParte phone">
                    <div class="cntTesto">
                        <div class="testo">
                            <div class="evento">{{ storia.evento }}</div>
                            <div class="data">{{ storia.data }}</div>
                            <div class="descrizione">{{ storia.descrizione }}</div>
                        </div>
                    </div>
                    <div class="cntFoto">
                        <img class="fotoStory" :src="storia.foto" />
                    </div>
                    <div class="cntBall">
                        <div class="ball">
                            <v-icon class="iconHeart" dark>
                                mdi-heart
                            </v-icon>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else>
                <div v-for="(storia, index) in story" :key="index" class="cntParte" :class="index % 2 != 0 ? 'reverse' : ''">
                    <div class="cntFoto" @mouseover="storia.fadeIn = true" @mouseleave="storia.fadeIn = false">
                        <img class="fotoStory" :class="{ 'animate__animated animate__pulse': storia.fadeIn }" :src="storia.foto" />
                    </div>
                    <div class="cntBall">
                        <div class="ball">
                            <v-icon class="iconHeart animate__animated animate__rotateIn" dark>
                                mdi-heart
                            </v-icon>
                        </div>
                    </div>
                    <div class="cntTesto">
                        <div class="testo">
                            <div class="evento">{{ storia.evento }}</div>
                            <div class="data">{{ storia.data }}</div>
                            <div class="descrizione">{{ storia.descrizione }}</div>
                        </div>
                    </div>
                </div>
            </template>

            <img class="imgFiore" src="../assets/green-flower.png" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            story: [
                {
                    foto: require("../assets/story1.jpg"),
                    evento: "La prima uscita",
                    data: "2015",
                    descrizione: "L'amore è arrivato per caso, la nostra prima uscita non era prevista eppure tra noi ci fu subito sintonia.",
                    fadeIn: false
                },
                {
                    foto: require("../assets/story2.jpg"),
                    evento: "Fidanzamento",
                    data: "7 Marzo 2015",
                    descrizione: "Più diversi che uguali. Abbiamo iniziato la nostra storia con gioia, spenzieratezza e amore da dare l'un l'altro.",
                    fadeIn: false
                },
                {
                    foto: require("../assets/story3.jpg"),
                    evento: "Decidono di sposarsi",
                    data: "Maggio 2021",
                    descrizione:
                        "Siamo cresciuti insieme, noi ed i nostri caratteri! Poi come per tutti col tempo la vita si è complicata..e noi ci siamo aiutati, supportati e sopportati. Tutto questo non ci ha fermato, abbiamo deciso di andare avanti insieme mano nella mano.",
                    fadeIn: false
                },
                {
                    foto: require("../assets/story4.jpg"),
                    evento: "Il giorno del matrimonio",
                    data: "26 Giugno 2022",
                    descrizione: "Diremo il nostro Si davanti a voi: davanti alle nostre famiglie, ai nostri amici e difronte a Dio.",
                    fadeIn: false
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.story {
    background: linear-gradient(180deg, rgb(129, 148, 255) 0%, rgb(166, 198, 245) 35%, rgb(191, 224, 255) 100%);
    .cntStory {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        .cntParte:hover {
            .iconHeart {
                animation: spin 500ms;
            }
        }
        .cntParte {
            margin-top: 3rem;
            margin-bottom: 3rem;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .cntFoto {
                display: flex;
                justify-content: center;
                .fotoStory {
                    width: 468px;
                    height: 320px;
                }
            }
            .cntBall {
                .ball {
                    width: 50px;
                    height: 50px;
                    border-radius: 30px;
                    background-color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .iconHeart {
                        font-size: 13px;
                        color: blue;
                    }
                }
            }
            .cntTesto {
                width: 32%;
                display: flex;
                justify-content: center;
                .testo {
                    .evento {
                        font-family: "Alex Brush", cursive !important;
                        font-size: 3rem;
                        color: white;
                    }
                    .data {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
        .reverse {
            flex-direction: row-reverse;
        }

        .cntParte.phone {
            flex-direction: column;
            justify-content: center;
            margin: 1rem;
            .cntTesto {
                width: unset;
            }
            .fotoStory {
                width: 340px;
                height: 220px;
                margin: 1rem;
                display: flex;
                justify-content: stretch;
            }
            .cntBall {
                margin-top: 2rem;
                .iconHeart {
                    animation: unset !important;
                }
            }
            .cntTesto {
                display: flex;
                justify-content: center;
                .testo {
                    .evento {
                        font-size: 2.5rem;
                    }
                    .data {
                        margin: 1.5rem 0;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>
